<template>
	<div id="PdfView">
	<div class="delete_img" @click="back">
    <img src="../../assets/img/delete.png"  />
  </div>
<!--		<div class="back">-->
<!--			<i class="iconfont"></i>-->
<!--		</div>-->
		<iframe
			v-if="pdfSrc"
			:src="`https://culture.aqitai.com/pdfjs/web/viewer.html?file=${pdfSrc}`"
			width="100%"
			height="99%"
		></iframe>
		<img v-if="music" @click="play()" class="imgs" :src="paused?yinpindongimg:yinpinimg" alt />
	</div>
</template>

<script>
export default {
	//监控data中的数据变化

	watch: {},
	components: {},
	data() {
		return {
			yinpinimg: require("../../assets/img/pause1.png"),
			yinpindongimg: require("../../assets/img/zhuan.gif"),
			pdfSrc: "",
			music: "",
			paused: false,
		};
	},
	methods: {
    		play() {
			this.paused = !this.paused;
			console.log(1);
			if (this.audioplay) {
				if (this.paused == true) {
					this.audioplay.play();
				} else {
					this.audioplay.pause();
				}
			} else {
				this.audioplay = new Audio();
				this.audioplay.src = this.music;
				this.audioplay.play();
			}
		},
		back() {
      console.log('后退')
			this.$router.go(-1);
      console.log('后退得得得')
			// if (this.$route.query.mark == 1) {
			//   this.$router.push({
			//     path: '/PaperInfo',
			//     query: {
			//       directory_id: this.$route.query.directory_id,
			//       magazine: this.$route.query.magazine
			//     }
			//   })
			// }else if (this.$route.query.mark == 2) {

			// }
		},
	},
	created() {
    //限制只可以微信
    var useragent = navigator.userAgent;

    if(window.location.pathname=='/zhPdfView'){
      if (useragent.match(/MicroMessenger/i) != 'MicroMessenger') {
        window.location.href = "wxError.html";
      }else if(useragent.match(/Macintosh/i) == 'Macintosh'){
        window.location.href = "wxError.html";
      }
    }

		this.pdfSrc = this.$route.query.url;
		if (this.$route.query.music) {
			this.music = this.$route.query.music;
		}
	},
	mounted() {
		const that = this;
		that.$nextTick(() => {
			var iframe = document.getElementById("commandIframe");
			if(iframe){
				if (iframe.attachEvent) {
					// 兼容IE写法
					iframe.attachEvent("onload", function () {
						// iframe加载完成后要进行的操作
						that.play();
					});
				} else {
					iframe.onload = function () {
						// iframe加载完成后要进行的操作
						that.play();
					};
				}
			}
		})
	},
	destroyed: function () {
		if (this.audioplay) {
			this.audioplay.pause();
		}
	},
};
</script>

<style lang="less">
#PdfView {
	position: relative;
	height: 100vh !important;
	iframe {
		height: 100vh;
	}
	.imgs {
		position: fixed;
		top: 1px;
		right: 65px;

		width: 30px;
		height: 30px;
		z-index: 9999999999999;
	}
	.delete_img {
		z-index: 9999999999999;
		width: 30px;
		height: 30px;
		position: fixed;
		right: 15px;
		top: 0px;
    img{
      width: 100%;
      height: 100%;
    }
	}
}
</style>
